import React from 'react';
import { FaHeart } from 'react-icons/fa';

interface LikeButtonProps {
  liked: boolean;
  likesCount: number;
  onLike: () => void;
}

const LikeButton: React.FC<LikeButtonProps> = ({ liked, likesCount, onLike }) => {
  return (
    <div className="flex items-center">
      <button
        type="button"
        title={liked ? 'Unlike' : 'Like'}
        className={`btn btn-circle btn-sm ${liked ? 'text-red-500' : 'text-gray-500'} mr-2`}
        onClick={onLike}
      >
        <FaHeart />
      </button>
      <span className="text-white mr-4">{likesCount.toLocaleString()}</span>
    </div>
  );
};

export default LikeButton;
