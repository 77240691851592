import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../types';

interface SearchResultsModalProps {
  results: User[];
  onClose: () => void;
}

const SearchResultsModal: React.FC<SearchResultsModalProps> = ({ results, onClose }) => {
  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Search Results</h3>
        <div className="mt-4">
          {results.length > 0 ? (
            <ul className="space-y-4">
              {results.map((user) => (
                <li key={user.id} className="flex items-center bg-gray-800 p-4 rounded-lg shadow-md">
                  <img
                    src={user.profileImg || '/avatars/boy1.png'}
                    alt="Profile"
                    className="w-10 h-10 rounded-full mr-3"
                  />
                  <div>
                    <Link
                      to={`/profile/${user.userName}`}
                      onClick={onClose}
                      className="text-white font-bold"
                    >
                      {user.userName}
                    </Link>
                    <p className="text-gray-400 text-sm">{user.bio || 'No bio available'}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-white">No users found</p>
          )}
        </div>
        <div className="modal-action">
          <button className="btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsModal;