import React from 'react';

interface CustomConfirmationModalProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const CustomConfirmationModal: React.FC<CustomConfirmationModalProps> = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg z-10">
        <p className="text-white mb-4">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            className="btn bg-red-500 hover:bg-red-600 text-white"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="btn bg-green-500 hover:bg-green-600 text-white"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
      <div className="fixed inset-0 bg-black opacity-50 z-0"></div>
    </div>
  );
};

export default CustomConfirmationModal;