import React from 'react';
import FriendItem from './FriendItem';
import { Friend } from '../../types';

interface FriendListProps {
  friends: Friend[];
  onRemoveFriend: (friendUserId: string) => void;
}

const FriendList: React.FC<FriendListProps> = ({ friends, onRemoveFriend }) => {
  if (friends.length === 0) {
    return <p>No friends to display.</p>;
  }

  return (
    <div>
      {friends.map((friend) => (
        <FriendItem 
          key={friend.id} 
          friend={{ 
            userId: friend.userId,
            username: friend.username, 
            profileImg: friend.profileImg 
          }} 
          onRemoveFriend={onRemoveFriend} 
        />
      ))}
    </div>
  );
};

export default FriendList;