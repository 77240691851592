import React from 'react';
import { Navigate } from 'react-router-dom';


interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const token = sessionStorage.getItem('token');
  const userId = sessionStorage.getItem('userId');
  const isAuthenticated = !!token && !!userId;

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateRoute;