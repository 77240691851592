import React from 'react';

interface SearchBarProps {
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  onSearch: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onChange, value, onSearch }) => {
  return (
    <div className="flex items-center space-x-2">
      <input
        type="text"
        placeholder={placeholder}
        className="input input-bordered"
        onChange={onChange}
        value={value}
      />
      <button className="btn btn-primary" onClick={onSearch}>
        Search
      </button>
    </div>
  );
};

export default SearchBar;