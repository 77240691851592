import React, { SVGProps } from 'react';

const OrangeMellonLogo: React.FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 259.29 69.4"
      {...props}
    >
      <path d="M0,38.19c0-13,8.46-19.41,18.62-19.41,5.8,0,10.36,2.88,13.02,7.62,2.76-4.83,7.32-7.62,13.11-7.62,10.26,0,18.62,6.41,18.62,19.41v24.44c0,3.07-2.49,5.56-5.56,5.56h0c-3.07,0-5.56-2.49-5.56-5.56v-23.51c0-7.24-2.66-10.96-7.51-10.96s-7.51,4.37-7.51,12.54v21.93c0,3.07-2.49,5.56-5.56,5.56h0c-3.07,0-5.56-2.49-5.56-5.56v-21.93c0-8.17-2.76-12.54-7.51-12.54s-7.51,3.72-7.51,10.96v23.51c0,3.07-2.49,5.56-5.56,5.56h0C2.49,68.19,0,65.7,0,62.63v-24.44Z" fill="#fff" strokeWidth="0"/>
      <path d="M68.31,38.7c0-13,8.74-19.97,19.48-19.97s19.57,7.43,19.57,18.2v5.52c0,2.59-2.1,4.7-4.7,4.7h-23.33v1.76c0,7.52,3.71,11.05,10.07,11.05,3.08,0,6.04-.41,8.96-1.06,2.49-.56,4.97.99,5.57,3.47h0c.61,2.49-.89,5.03-3.38,5.67-3.45.89-6.88,1.31-11.16,1.31-12.54,0-21.09-7.06-21.09-20.43v-10.22ZM96.25,39.17v-2.23c0-5.02-3.33-8.82-8.46-8.82s-8.46,3.53-8.46,9.47v1.58h16.91Z" fill="#fff" strokeWidth="0"/>
      <path d="M117.1.08h0c3.39-.57,6.47,2.05,6.47,5.48v57.07c0,3.07-2.49,5.56-5.56,5.56h0c-3.07,0-5.56-2.49-5.56-5.56V5.56c0-2.72,1.96-5.03,4.64-5.48Z" fill="#fff" strokeWidth="0"/>
      <path d="M133.31.08h0c3.39-.57,6.47,2.05,6.47,5.48v57.07c0,3.07-2.49,5.56-5.56,5.56h0c-3.07,0-5.56-2.49-5.56-5.56V5.56c0-2.72,1.96-5.03,4.64-5.48Z" fill="#8aca7a" strokeWidth="0"/>
      <path d="M144.87,39.86c0-13.65,8.65-21.08,20.43-21.08s20.52,7.43,20.52,21.18v8.27c0,13.65-8.65,21.18-20.52,21.18s-20.43-7.52-20.43-21.18v-8.36ZM165.3,60.01c6.27,0,9.5-3.99,9.5-12.26v-7.34c0-8.27-3.23-12.26-9.5-12.26s-9.41,3.99-9.41,12.26v7.34c0,8.27,3.23,12.26,9.41,12.26Z" fill="#8aca7a" strokeWidth="0"/>
      <path d="M190.92,38.65c0-13,8.84-19.88,19.67-19.88s19.48,6.87,19.48,19.88v23.98c0,3.07-2.49,5.56-5.56,5.56h0c-3.07,0-5.56-2.49-5.56-5.56v-23.51c0-7.24-3.33-10.96-8.36-10.96s-8.65,3.72-8.65,10.96v23.56c0,3.04-2.47,5.51-5.51,5.51h0c-3.04,0-5.51-2.47-5.51-5.51v-24.02Z" fill="#8aca7a" strokeWidth="0"/>
      <path d="M247.53,53.97c.24-.07.31-4.17-2.58-7-1.39-1.36-3.9-2.64-6.26-1.84-2.49.84-3.88,3.65-3.46,4.13.42.48,2.68-2.31,5.78-1.91,2,.26,3.43,1.73,4.05,2.37,2.01,2.07,2.29,4.31,2.47,4.25Z" fill="#8aca7a" strokeWidth="0"/>
      <path d="M247.46,53.81c-.36-.18-.31-6.24,2.58-10.04.18-.23,3.2-4.1,6.26-2.64,1.92.91,3.22,3.62,2.95,4.23-.23.5-1.42-.71-3.5-.79-.13,0-2.22-.05-4.05,1.32-3.27,2.46-3.88,8.1-4.24,7.93Z" fill="#8aca7a" strokeWidth="0"/>
      <circle cx="247.45" cy="60.45" r="7.74" fill="#ff7d5c" strokeWidth="0"/>
    </svg>
  );
  
  export default OrangeMellonLogo;