import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchFriendRequests, sendFriendRequest, acceptFriendRequest, fetchSentFriendRequests, fetchFriends, removeFriendRequest } from '../utils/api';
import { FriendRequestParams, Notification, User, Friend } from '../types';

export const useFetchFriendRequests = (userId: string) => {
  return useQuery<Notification[], Error>({
    queryKey: ['friendRequests', userId],
    queryFn: () => fetchFriendRequests(userId),
    enabled: !!userId,
  });
};

export const useSendFriendRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: FriendRequestParams) => sendFriendRequest(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['friendStatus'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
    },
  });
};

export const useAcceptFriendRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, FriendRequestParams>({
    mutationFn: acceptFriendRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['friendRequests'] });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
      queryClient.invalidateQueries({ queryKey: ['friends'] });
    },
  });
};

export const useRemoveFriendRequest = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, FriendRequestParams>({
    mutationFn: removeFriendRequest,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['friendRequests'] });
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export const useFetchSentFriendRequests = (userId: string) => {
  return useQuery<User[], Error>({
    queryKey: ['sentFriendRequests', userId],
    queryFn: () => fetchSentFriendRequests(userId),
    enabled: !!userId,
  });
};

export const useFetchFriends = (userId: string) => {
  return useQuery<Friend[], Error>({
    queryKey: ['friends', userId],
    queryFn: () => fetchFriends(userId),
    enabled: !!userId,
  });
};