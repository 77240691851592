import React, { useState } from "react";
import { fetchAllUsersReport, fetchUserPostsReport } from "../../utils/api";

const AdminReports: React.FC = () => {
    const [allUsersReport, setAllUsersReport] = useState<any[]>([]);
    const [userId, setUserId] = useState('');
    const [userPostsReport, setUserPostsReport] = useState<any[]>([]);
  
    const handleGenerateAllUsersReport = async () => {
      try {
        const report = await fetchAllUsersReport();
        setAllUsersReport(report);
      } catch (error) {
        console.error('Error generating all users report:', error);
      }
    };
  
    const handleGenerateUserPostsReport = async () => {
      if (!userId) return;
      try {
        const report = await fetchUserPostsReport(userId);
        setUserPostsReport(report);
      } catch (error) {
        console.error(`Error generating posts report for user ${userId}:`, error);
      }
    };
  
    const handleClearReports = () => {
      setAllUsersReport([]);
      setUserPostsReport([]);
      setUserId('');
    };
  
    return (
      <div className="admin-reports">
        <h2 className="font-bold text-lg mb-4">Reports</h2>
        <div className="flex justify-left mb-4">
          <button className="btn bg-gray text-white" onClick={handleClearReports}>
            Clear Reports
          </button>
        </div>
        <div className="mb-4">
          <button
            className="btn bg-blue-600 text-white"
            onClick={handleGenerateAllUsersReport}
          >
            Generate All Users Report
          </button>
          {allUsersReport.length > 0 ? (
            <div className="mt-4">
              <h3 className="font-bold">All Users Report</h3>
              <table className="w-full mt-2">
                <thead>
                  <tr>
                    <th className="border px-2 py-1">User ID</th>
                    <th className="border px-2 py-1">Username</th>
                    <th className="border px-2 py-1">Total Posts</th>
                  </tr>
                </thead>
                <tbody>
                  {allUsersReport.map((user) => (
                    <tr key={user.userId}>
                      <td className="border px-2 py-1">{user.userId}</td>
                      <td className="border px-2 py-1">{user.username}</td>
                      <td className="border px-2 py-1">{user.totalPosts}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-white mt-4">No users found.</p>
          )}
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className="p-2 bg-gray-800 rounded"
          />
          <button
            className="btn bg-blue-600 text-white ml-2"
            onClick={handleGenerateUserPostsReport}
          >
            Generate User Posts Report
          </button>
          {userPostsReport.length > 0 ? (
            <div className="mt-4">
              <h3 className="font-bold">User Posts Report</h3>
              <table className="w-full mt-2">
                <thead>
                  <tr>
                    <th className="border px-2 py-1">Post ID</th>
                    <th className="border px-2 py-1">Timestamp</th>
                    <th className="border px-2 py-1">Content</th>
                    <th className="border px-2 py-1">Image URL</th>
                  </tr>
                </thead>
                <tbody>
                  {userPostsReport.map((post) => (
                    <tr key={post.postId}>
                      <td className="border px-2 py-1">{post.postId}</td>
                      <td className="border px-2 py-1">{new Date(post.timestamp).toLocaleString()}</td>
                      <td className="border px-2 py-1">{post.content}</td>
                      <td className="border px-2 py-1">{post.imageUrl}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-white mt-4">User has no posts.</p>
          )}
        </div>
      </div>
    );
  };
  
  export default AdminReports;
