import React, { useState } from 'react';
import { User, Profile } from '../../types';

interface EditProfileModalProps {
  profile: Profile;
  onClose: () => void;
  updateUserProfile: (updatedUser: Partial<User>) => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({ profile, onClose, updateUserProfile }) => {
  const [bio, setBio] = useState(profile.bio || "");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);


    const updatedUser: Partial<User> = {
      id: profile.id,
      bio: bio || "This is a bio",
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      userName: profile.userName,
      hashedPassword: profile.hashedPassword || "",
      profileImg: profile.profileImg,
      friends: profile.friends,
      receivedFriendRequests: profile.receivedFriendRequests,
      sentFriendRequests: profile.sentFriendRequests,
      posts: profile.posts.map(post => post.id),
    };

    updateUserProfile(updatedUser);
    onClose();
  };


  React.useEffect(() => {
    if (!isSaving) return;
    const closeModalAfterSave = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      onClose();
    };
    closeModalAfterSave();
  }, [isSaving, onClose]);

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Edit Profile</h3>
        <div className="mb-4">
          <textarea
            placeholder="Bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
            rows={4}
          ></textarea>
        </div>
        <div className="modal-action">
          <button className="btn" onClick={onClose}>
            Close
          </button>
          <button className="btn bg-primary text-black" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default EditProfileModal;