import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { usePosts, useToggleLikePost, useDeletePost, useAddComment, useUpdatePost } from '../../hooks/usePosts';
import CommentsModal from '../posts/CommentsModal';
import LikeButton from './LikeButton';
import { FaComment, FaPencilAlt } from 'react-icons/fa';
import { IoEyeOffOutline } from 'react-icons/io5';
import { Post as PostType, Comment as CommentType } from '../../types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Link } from 'react-router-dom';
import FullImageModal from '../common/FullImageModal';
import EditPostModal from '../posts/EditPostModal';

const HomeFeed: React.FC = () => {
  const { data: posts, isLoading, error, refetch } = usePosts();
  const likeMutation = useToggleLikePost();
  const deleteMutation = useDeletePost();
  const updatePostMutation = useUpdatePost();
  const addCommentMutation = useAddComment();
  const { data: currentUser } = useCurrentUser();
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<PostType | null>(null);
  const [hiddenPosts, setHiddenPosts] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const openCommentsModal = (post: PostType) => {
    setSelectedPost(post);
    setIsCommentsModalOpen(true);
  };

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false);
    setSelectedPost(null);
  };

  const handleLike = (postId: string) => {
    if (currentUser) {
      likeMutation.mutate({ postId, userId: currentUser.id });
    }
  };

  const handleAddComment = (postId: string, comment: CommentType) => {
    if (currentUser) {
      addCommentMutation.mutate(
        { postId, comment },
        {
          onSuccess: () => {
            refetch();
            closeCommentsModal();
          },
          onError: () => {
            setSuccessMessage('Failed to add comment.');
          },
        }
      );
    }
  };

  const handleHidePost = (postId: string) => {
    setHiddenPosts([...hiddenPosts, postId]);
  };

  const handleImageClick = (url: string) => {
    setSelectedImageUrl(url);
  };

  const closeFullImageModal = () => {
    setSelectedImageUrl(null);
  };

  const handleEdit = (post: PostType) => {
    setSelectedPost(post);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = (postId: string, content: string, removeImage: boolean, isPrivate: boolean) => {
    if (currentUser) {
      updatePostMutation.mutate(
        { postId, userId: currentUser.id, content, removeImage, isPrivate },
        {
          onSuccess: () => {
            setSuccessMessage('Post updated successfully.');
            refetch();
            setIsEditModalOpen(false);
          },
          onError: () => {
            setSuccessMessage('Failed to update post.');
          },
        }
      );
    }
  };

  const handleDelete = (postId: string) => {
    deleteMutation.mutate(postId, {
      onSuccess: () => {
        setSuccessMessage('Post deleted successfully.');
        refetch();
        setIsEditModalOpen(false);
      },
      onError: () => {
        setSuccessMessage('Failed to delete post.');
      },
    });
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedPost(null);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading posts</div>;
  if (!posts || posts.length === 0) return <div>No posts available</div>;

  const filteredPosts = posts.filter((post) => !post.isPrivate);

  const sortedPosts = filteredPosts.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <div className="w-full">
      {successMessage && (
        <div className="bg-green-500 text-white p-4 rounded mb-4">{successMessage}</div>
      )}
      {sortedPosts
        .filter(post => !hiddenPosts.includes(post.id))
        .map((post, index) => (
          <motion.div
            key={post.id}
            className={`relative bg-gray-800 p-4 rounded-lg mb-4 shadow-md ${
              post.imageUrl ? 'md:w-1/2 w-full' : 'w-full ml-auto'
            }`}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <div className="absolute top-2 right-2 flex space-x-2">
              {currentUser?.id === post.userId ? (
                <button
                  className="text-gray-400 hover:text-blue-500"
                  onClick={() => handleEdit(post)}
                  title="Edit Post"
                  type="button"
                >
                  <FaPencilAlt className="w-5 h-5" />
                </button>
              ) : (
                <button
                  className="text-gray-400 hover:text-yellow-500"
                  onClick={() => handleHidePost(post.id)}
                  title="Hide Post"
                  type="button"
                >
                  <IoEyeOffOutline className="w-5 h-5" />
                </button>
              )}
            </div>
            <div className="flex items-center mb-4">
              <Link to={`/profile/${post.username}`} className="flex items-center">
                <img src={post.profileImg || '/avatars/boy1.png'} alt="Profile" className="w-12 h-12 rounded-full mr-3" />
                <div>
                  <div className="text-white font-bold">{post.username}</div>
                  <div className="text-gray-400 text-sm">{new Date(post.timestamp).toLocaleString()}</div>
                </div>
              </Link>
            </div>
            <div className="text-white text-left pl-10 mb-4 text-xl">{post.content}</div>
            {post.imageUrl && (
              <img
                src={post.imageUrl}
                alt="Post"
                className="w-full h-auto max-h-64 object-cover mt-2 cursor-pointer"
                onClick={() => handleImageClick(post.imageUrl ?? '')}
              />
            )}
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center bg-gray-700 p-2 rounded-lg">
                <LikeButton
                  liked={currentUser ? post.likes.includes(currentUser.id) : false}
                  likesCount={post.likes.length}
                  onLike={() => handleLike(post.id)}
                />
                <button
                  className="btn btn-circle btn-sm text-primary mr-2"
                  onClick={() => openCommentsModal(post)}
                  title="Comment on Post"
                  type="button"
                >
                  <FaComment />
                </button>
                <span className="text-white">{post.comments.length}</span>
              </div>
            </div>
            {isCommentsModalOpen && selectedPost && (
              <CommentsModal post={selectedPost} onClose={closeCommentsModal} onAddComment={handleAddComment} />
            )}
          </motion.div>
        ))}
      {selectedImageUrl && <FullImageModal url={selectedImageUrl} onClose={closeFullImageModal} />}

      {isEditModalOpen && selectedPost && (
        <EditPostModal
          post={selectedPost}
          onClose={closeEditModal}
          onSave={handleSaveEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default HomeFeed;
