import React, { useState } from 'react';
import { Post } from '../../types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import CustomConfirmationModal from '../common/CustomConfirmationModal';

interface EditPostModalProps {
  post: Post;
  onClose: () => void;
  onSave: (postId: string, content: string, removeImage: boolean, isPrivate: boolean) => void;
  onDelete: (postId: string) => void;
}

const EditPostModal: React.FC<EditPostModalProps> = ({ post, onClose, onSave, onDelete }) => {
  const [editedContent, setEditedContent] = useState(post.content || '');
  const [removeImage, setRemoveImage] = useState(false);
  const [isPrivate, setIsPrivate] = useState(post.isPrivate || false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { data: currentUser } = useCurrentUser();

  const handleSaveEdit = () => {
    if (currentUser) {
      onSave(post.id, editedContent, removeImage, isPrivate);
    }
  };

  const handleDeletePost = () => {
    setIsConfirmationModalOpen(true);
  };

  const confirmDelete = () => {
    if (currentUser) {
      onDelete(post.id);
    }
    setIsConfirmationModalOpen(false);
  };

  const cancelDelete = () => {
    setIsConfirmationModalOpen(false);
  };

  const isSaveDisabled = !editedContent.trim() && (!post.imageUrl || removeImage);

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Edit Post</h3>
        <div className="original-post mb-4 p-4 bg-gray-800 rounded-lg">
          <div className="flex items-center mb-4">
            <img
              src={post.profileImg || '/avatars/boy1.png'}
              alt="Profile"
              className="w-10 h-10 rounded-full mr-3"
            />
            <div>
              <div className="text-white font-bold">{post.username}</div>
              <div className="text-gray-400 text-sm">{new Date(post.timestamp).toLocaleString()}</div>
            </div>
          </div>
          <p className="text-white">{post.content}</p>
          {post.imageUrl && !removeImage && (
            <img src={post.imageUrl} alt="Post" className="w-full mt-2 rounded" />
          )}
        </div>
        <div className="modal-action flex flex-col">
          <textarea
            className="w-full p-2 rounded bg-gray-700 text-white mb-2"
            rows={4}
            placeholder="Edit your post..."
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            aria-label="Edit Post"
          />
          {post.imageUrl && (
            <div className="flex items-center mb-2">
              <input
                type="checkbox"
                id="removeImage"
                checked={removeImage}
                onChange={() => setRemoveImage(!removeImage)}
                className="mr-2"
              />
              <label htmlFor="removeImage" className="text-white">
                Remove image
              </label>
            </div>
          )}
          <div className="flex items-center mb-2">
            <label className="mr-2 text-white">Private Post</label>
            <input
              type="checkbox"
              checked={isPrivate}
              onChange={() => setIsPrivate(!isPrivate)}
              className="toggle"
            />
          </div>
          <div className="flex justify-between space-x-2">
            <button className="btn bg-red-500 hover:bg-red-600" onClick={handleDeletePost}>
              Delete
            </button>
            <div className="flex space-x-2">
              <button className="btn" onClick={onClose}>
                Close
              </button>
              <button
                className={`btn btn-primary ${isSaveDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleSaveEdit}
                disabled={isSaveDisabled}
                title={isSaveDisabled ? 'Content and image cannot both be empty' : ''}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <CustomConfirmationModal
        isOpen={isConfirmationModalOpen}
        message="Are you sure you want to delete this post?"
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
    </dialog>
  );
};

export default EditPostModal;