import React, { useState } from "react";
import { MdHomeFilled } from "react-icons/md";
import {
  IoNotifications,
  IoNotificationsOutline,
  IoSettingsOutline,
  IoMenu,
} from "react-icons/io5";
import { FaUser, FaAddressBook } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import OrangeMellonLogo from "../svgs/OrangeMellonLogo";
import CreatePost from "../posts/CreatePost";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useFetchNotifications } from "../../hooks/useNotifications";

const Sidebar: React.FC = () => {
  const { data: user, isLoading, error } = useCurrentUser();
  const { data: notifications } = useFetchNotifications(user?.id || "");
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const getLinkClasses = (path: string) => {
    return location.pathname === path
      ? "flex gap-3 items-center bg-gray-700 transition-all rounded-full duration-300 py-2 px-4 w-full"
      : "flex gap-3 items-center hover:bg-gray-700 transition-all rounded-full duration-300 py-2 px-4 w-full";
  };

  if (isLoading) {
    return (
      <div className="w-64 h-screen flex-shrink-0 bg-gray-800 flex items-center justify-center">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-64 h-screen flex-shrink-0 bg-gray-800 flex items-center justify-center">
        Error loading sidebar
      </div>
    );
  }

  return (
    <>
      <button
        className="md:hidden p-2 fixed top-1 left-2 z-20"
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        <IoMenu className="w-6 h-6" />
      </button>
      <div
        className={`fixed md:relative z-10 md:z-0 w-64 h-screen bg-gray-800 transform transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 flex flex-col`}
      >
        <div className="flex flex-col h-full border-r border-gray-700 pb-4">
          <div className="flex justify-center items-center py-4">
            <OrangeMellonLogo className="w-16 h-16" />
          </div>
          <ul className="flex flex-col gap-3 px-4 mt-4 flex-grow overflow-y-auto">
            <li>
              <Link to="/" className={getLinkClasses("/")}>
                <MdHomeFilled className="w-8 h-8" />
                <span className="text-lg">Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/notifications"
                className={getLinkClasses("/notifications")}
              >
                {notifications && notifications.length > 0 ? (
                  <IoNotifications className="w-6 h-6 text-red-500" />
                ) : (
                  <IoNotificationsOutline className="w-6 h-6" />
                )}
                <span className="text-lg">Notifications</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/profile/${user?.userName}`}
                className={getLinkClasses(`/profile/${user?.userName}`)}
              >
                <FaUser className="w-6 h-6" />
                <span className="text-lg">Profile</span>
              </Link>
            </li>
            <li>
              <Link to="/friends" className={getLinkClasses("/friends")}>
                <FaAddressBook className="w-6 h-6" />
                <span className="text-lg">Friends</span>
              </Link>
            </li>
            <li>
              <div className="px-4 py-2">
                {user && <CreatePost userData={user} onClose={() => {}} />}
              </div>
            </li>
            {/* Settings link for small screens */}
            <li className="block md:hidden px-4 mt-4">
              <Link
                to="/settings"
                className="flex gap-2 items-start transition-all duration-300 hover:bg-gray-700 py-2 px-4 rounded-full w-full"
              >
                <div className="avatar">
                  <div className="w-8 rounded-full">
                    <img
                      src={user?.profileImg || "/avatars/boy1.png"}
                      alt="User avatar"
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-between flex-1">
                  <p className="text-white font-bold text-sm">
                    {user?.firstName} {user?.lastName}
                  </p>
                  <p className="text-gray-500 text-sm">@{user?.userName}</p>
                </div>
                <IoSettingsOutline className="w-5 h-5 cursor-pointer" />
              </Link>
            </li>
          </ul>
          <Link
            to="/settings"
            className="hidden md:flex gap-2 items-start transition-all duration-300 hover:bg-gray-700 py-2 px-4 rounded-full w-full"
          >
            <div className="avatar">
              <div className="w-8 rounded-full">
                <img
                  src={user?.profileImg || "/avatars/boy1.png"}
                  alt="User avatar"
                />
              </div>
            </div>
            <div className="flex flex-col justify-between flex-1">
              <p className="text-white font-bold text-sm">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="text-gray-500 text-sm">@{user?.userName}</p>
            </div>
            <IoSettingsOutline className="w-5 h-5 cursor-pointer" />
          </Link>
        </div>
      </div>
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-5 bg-black opacity-50 md:hidden"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
