import React from 'react';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import Rightpanel from '../components/common/Rightpanel';
import HomeFeed from '../components/common/HomeFeed';

const HomePage: React.FC = () => {
  return (
    <>
      <Header />
      <div className="flex w-full h-screen overflow-hidden">
        <Sidebar />
        <div className="flex-1 bg-gray-900 p-4 mt-16 overflow-y-auto">
          <HomeFeed />
        </div>
        <Rightpanel />
      </div>
    </>
  );
};

export default HomePage;