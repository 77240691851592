import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import OrangeMellonLogo from '../svgs/OrangeMellonLogo';
import SearchBar from './SearchBar';
import SearchResultsModal from './SearchResultsModal';
import { useSearchUsers } from '../../hooks/useSearchUsers';


const Header: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: searchResults, refetch: searchUsers } = useSearchUsers(searchValue);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    searchUsers();
    setIsModalOpen(true);
  };

  return (
    <nav className="navbar bg-base-100 fixed top-0 left-0 right-0 z-10 shadow-md">
      <div className="container flex flex-row items-center justify-between">
        <Link to="/" className="flex items-center pl-4">
          <OrangeMellonLogo className="w-32 h-12 fill-white hidden md:block" />
        </Link>
        <div className="flex-grow" />
        <div className="flex items-center space-x-2">
          <SearchBar
            placeholder="Search..."
            onChange={handleSearchChange}
            value={searchValue}
            onSearch={handleSearch}
          />
        </div>
      </div>
      {isModalOpen && (
        <SearchResultsModal results={searchResults || []} onClose={() => setIsModalOpen(false)} />
      )}
    </nav>
  );
};

export default Header;