import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import Rightpanel from "../components/common/Rightpanel";
import AdminReports from "../components/common/AdminReports";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { updateUserProfile } from "../utils/api";
import { useDeleteAccount } from "../hooks/useDeleteAccount";
import CustomConfirmationModal from "../components/common/CustomConfirmationModal";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: currentUser, refetch: refetchCurrentUser } = useCurrentUser();
  const { mutate: deleteAccountMutation } = useDeleteAccount();


  const [firstName, setFirstName] = useState(currentUser?.firstName || "");
  const [lastName, setLastName] = useState(currentUser?.lastName || "");
  const [email, setEmail] = useState(currentUser?.email || "");


  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isAdminView, setIsAdminView] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!firstName) errors.firstName = "First name is required.";
    if (!lastName) errors.lastName = "Last name is required.";
    if (!email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid email format.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDeleteAccount = () => {
    if (currentUser?.id) {
      deleteAccountMutation(currentUser.id, {
        onSuccess: () => {
          navigate("/login");
        },
      });
    }
  };

  const handleOpenConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleChangePassword = (e: FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("New passwords do not match");
      return;
    }
    console.log("Password changed successfully");
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const handleToggleAdminView = () => {
    setIsAdminView(!isAdminView);
  };

  const handleToggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleUserUpdate = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      if (currentUser?.id) {
        await updateUserProfile({
          id: currentUser.id,
          firstName,
          lastName,
          email,
          userName: currentUser.userName,
          hashedPassword: currentUser.hashedPassword,
        });
        setSuccessMessage("User information updated successfully.");
        refetchCurrentUser();
      }
    } catch (error) {
      console.error("Failed to update user:", error);
      alert("Failed to update user information.");
    }
    setIsEditing(false);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("token");
    refetchCurrentUser();
    navigate("/login");
  };

  return (
    <>
      <Header />
      <div className="flex w-full h-screen overflow-hidden">
        <Sidebar />
        <div className="flex-1 bg-gray-900 p-4 mt-16 flex flex-col overflow-y-auto">
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <p className="font-bold">Settings</p>
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <label className="text-sm font-medium mr-2" htmlFor="editModeCheckbox">
                  Edit Mode
                </label>
                <input
                  id="editModeCheckbox"
                  type="checkbox"
                  checked={isEditing}
                  onChange={handleToggleEditing}
                  className="toggle-checkbox"
                />
              </div>
              <div className="flex items-center">
                <label className="text-sm font-medium mr-2" htmlFor="adminViewCheckbox">
                  View Reports
                </label>
                <input
                  id="adminViewCheckbox"
                  type="checkbox"
                  checked={isAdminView}
                  onChange={handleToggleAdminView}
                  className="toggle-checkbox"
                />
              </div>
              <button className="btn bg-blue-600 text-white" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>

          <div className="w-full max-w-md mt-4 mx-auto">
            <form onSubmit={handleUserUpdate} className="space-y-4">
              <h3 className="text-lg font-medium text-white">User Information</h3>

              {successMessage && (
                <div className="bg-green-600 text-white p-2 rounded mb-2">
                  {successMessage}
                </div>
              )}

              <div>
                <label className="block text-sm font-medium" htmlFor="firstName">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  title="First Name"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                  disabled={!isEditing}
                />
                {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  title="Last Name"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                  disabled={!isEditing}
                />
                {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  title="Email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                  disabled={!isEditing}
                />
                {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
              </div>

              {isEditing && (
                <button type="submit" className="btn bg-blue-600 text-white">
                  Save Changes
                </button>
              )}
            </form>
          </div>

          <div className="w-full max-w-md mt-8 mx-auto">
            <form onSubmit={handleChangePassword} className="space-y-4">
              <h3 className="text-lg font-medium text-white">Change Password</h3>
              <div>
                <label className="block text-sm font-medium" htmlFor="oldPassword">
                  Old Password
                </label>
                <input
                  id="oldPassword"
                  type="password"
                  title="Old Password"
                  placeholder="Enter old password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium" htmlFor="newPassword">
                  New Password
                </label>
                <input
                  id="newPassword"
                  type="password"
                  title="New Password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                />
              </div>
              <div>
                <label className="block text-sm font-medium" htmlFor="confirmPassword">
                  Confirm New Password
                </label>
                <input
                  id="confirmPassword"
                  type="password"
                  title="Confirm New Password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full p-2 mt-1 bg-gray-800 rounded"
                />
              </div>
              <button type="submit" className="btn bg-blue-600 text-white">
                Change Password
              </button>
            </form>
          </div>

          <div className="w-full max-w-md mt-8 mx-auto">
            <button
              className="btn bg-red-600 text-white"
              onClick={handleOpenConfirmationModal}
            >
              Delete Account
            </button>
          </div>
          {isAdminView && (
            <div className="w-full max-w-md mt-8 mx-auto">
              <AdminReports />
            </div>
          )}
        </div>
        <Rightpanel />
      </div>

      <CustomConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCancel={handleCloseConfirmationModal}
        onConfirm={handleDeleteAccount}
        message="Are you sure you want to delete your account? This action cannot be undone."
      />
    </>
  );
};

export default SettingsPage;
