import { useQuery } from '@tanstack/react-query';
import { searchUsers, fetchUsernameById } from '../utils/api';
import { User } from '../types';

export const useSearchUsers = (query: string) => {
  return useQuery<User[], Error>({
    queryKey: ['searchUsers', query],
    queryFn: () => searchUsers(query),
    enabled: !!query,
  });
};

export const useUsernameById = (userId: string) => {
  return useQuery<string, Error>({
    queryKey: ['username', userId], 
    queryFn: () => fetchUsernameById(userId), 
    enabled: !!userId, 
  });
};