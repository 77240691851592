import React from 'react';
import { FriendRequest } from '../../types';

interface FriendRequestListProps {
  friendRequests: FriendRequest[];
  acceptFriendRequest: (fromUserId: string) => void;
  removeFriendRequest: (fromUserId: string) => void;
}

const FriendRequestList: React.FC<FriendRequestListProps> = ({
  friendRequests,
  acceptFriendRequest,
  removeFriendRequest,
}) => {
  if (friendRequests.length === 0) {
    return <div className="text-white">No friend requests found.</div>;
  }

  return (
    <div className="space-y-4">
      {friendRequests.map((request) => (
        <div
          key={request.id}
          className="bg-gray-800 p-4 rounded-lg shadow-md relative"
        >
          <div className="flex items-center mb-4">
            <img
              src={request.profileImg || '/avatars/boy1.png'}
              alt="Profile"
              className="w-12 h-12 rounded-full mr-3"
            />
            <div>
              <div className="text-white font-bold">@{request.username}</div>
              <div className="text-gray-400 text-sm">
                {new Date(request.timestamp).toLocaleString()}
              </div>
            </div>
          </div>
          <div className="text-white text-left mb-4">
            <p>sent you a friend request</p>
            <div className="flex space-x-2">
              <button
                className="btn btn-primary"
                onClick={() => acceptFriendRequest(request.userId)}
              >
                Accept
              </button>
              <button
                className="btn btn-danger"
                onClick={() => removeFriendRequest(request.userId)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FriendRequestList;