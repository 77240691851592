import React, { useEffect } from 'react';
import Sidebar from '../components/common/Sidebar';
import Header from '../components/common/Header';
import Rightpanel from '../components/common/Rightpanel';
import { useFetchFriends, useDeleteFriend } from '../hooks/useFriends';
import { useCurrentUser } from '../hooks/useCurrentUser';
import FriendList from '../components/friends/FriendList';
import PendingFriendRequests from '../components/friends/PendingFriendRequests';
import { useFetchSentFriendRequests } from '../hooks/useFriendRequests';

const FriendsPage: React.FC = () => {
  const { data: currentUser, isLoading: userLoading, error: userError } = useCurrentUser();
  const { data: friends, isLoading: friendsLoading, error: friendsError, refetch: refetchFriends } = useFetchFriends(currentUser?.id || '');
  const { data: sentRequests, isLoading: sentRequestsLoading, error: sentRequestsError } = useFetchSentFriendRequests(currentUser?.id || '');
  const removeFriendMutation = useDeleteFriend();
  

  useEffect(() => {
    if (friendsError) {
      console.error('Error fetching friends:', friendsError);
    }
    if (sentRequestsError) {
      console.error('Error fetching sent friend requests:', sentRequestsError);
    }
  }, [friends, sentRequests, friendsError, sentRequestsError]);

  const handleRemoveFriend = (friendUserId: string) => {
    if (currentUser) {
      removeFriendMutation.mutate(
        { userId: currentUser.id, friendUserId },
        {
          onSuccess: () => {
            refetchFriends();
          },
          onError: (error: Error) => {
            console.error('Error removing friend:', error);
          },
        }
      );
    }
  };

  if (userLoading) return <div>Loading user...</div>;
  if (userError) return <div>Error loading user: {userError.message}</div>;

  return (
    <>
      <Header />
      <div className="flex w-full h-screen">
        <Sidebar />
        <div className="flex-1 bg-gray-900 p-4 mt-16 flex flex-col overflow-y-auto">
          <div className="flex justify-between items-center p-4 border-b border-gray-700 w-full">
            <p className="font-bold">Friends</p>
          </div>
          {friendsLoading ? (
            <div className="text-center p-4">Loading friends...</div>
          ) : friendsError ? (
            <div className="text-center p-4 text-red-500">Error loading friends: {friendsError.message}</div>
          ) : (
            <FriendList friends={friends || []} onRemoveFriend={handleRemoveFriend} />
          )}
          <div className="flex justify-between items-center p-4 border-b border-gray-700 w-full mt-4">
            <p className="font-bold">Pending Friend Requests</p>
          </div>
          {sentRequestsLoading ? (
            <div className="text-center p-4">Loading pending friend requests...</div>
          ) : sentRequestsError ? (
            <div className="text-center p-4 text-red-500">Error loading pending friend requests: {sentRequestsError.message}</div>
          ) : (
            <PendingFriendRequests sentRequests={sentRequests || []} />
          )}
        </div>
        <Rightpanel />
      </div>
    </>
  );
};

export default FriendsPage;