import { useQuery } from '@tanstack/react-query';
import { fetchCurrentUser } from '../utils/api';
import { User } from '../types';


export const useCurrentUser = () => {
  return useQuery<User | null, Error>({
    queryKey: ['currentUser'],
    queryFn: fetchCurrentUser,
    initialData: null, 
  });
};



