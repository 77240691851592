import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImage, uploadProfileImage } from '../utils/api';

const useImageUpload = (onUploadSuccess: (url: string, uploadedFile: File) => void) => {
  const [preview, setPreview] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file) => {
      try {
        const imageUrl = await uploadImage(file);
        console.log('Image uploaded successfully:', imageUrl);
        onUploadSuccess(imageUrl, file);

        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    });
  }, [onUploadSuccess]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': ['.jpeg', '.jpg', '.png'] } });

  return { getRootProps, getInputProps, preview };
};

const useProfileImageUpload = (userId: string, onUploadSuccess: (url: string, file: File) => void) => {
  const [preview, setPreview] = useState<string | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file) => {
      try {
        const imageUrl = await uploadProfileImage(userId, file);
        console.log('Image uploaded successfully:', imageUrl);
        onUploadSuccess(imageUrl, file);

        const previewUrl = URL.createObjectURL(file);
        setPreview(previewUrl);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    });
  }, [userId, onUploadSuccess]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { 'image/*': ['.jpeg', '.jpg', '.png'] } });

  return { getRootProps, getInputProps, preview };
};

export { useImageUpload, useProfileImageUpload };