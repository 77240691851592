import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchPosts, createPost, toggleLikePost, deletePost, addComment, fetchUserPosts, updatePost } from '../utils/api';
import { Post, Comment } from '../types';

export const usePosts = () => {
  return useQuery<Post[], Error>({
    queryKey: ['posts'],
    queryFn: fetchPosts,
  });
};

export const useUserPosts = (userId: string) => {
  return useQuery<Post[], Error>({
    queryKey: ['userPosts', userId],
    queryFn: () => fetchUserPosts(userId),
    enabled: !!userId,
  });
};

export const useCreatePost = () => {
  const queryClient = useQueryClient();
  return useMutation<Post, Error, { content: string; userId: string; file?: File; isPrivate: boolean }>({
    mutationFn: ({ content, userId, file, isPrivate }) => createPost(content, userId, file, isPrivate),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['userPosts', data.userId] });
    },
  });
};

export const useUpdatePost = () => {
  const queryClient = useQueryClient();
  return useMutation<Post, Error, { postId: string; userId: string; content: string; removeImage: boolean; isPrivate: boolean }>({
    mutationFn: updatePost,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      queryClient.invalidateQueries({ queryKey: ['userPosts', data.userId] });
    },
    onError: (error) => {
      console.error('Failed to update post:', error);
      alert('Failed to update post. Please try again.');
    },
  });
};


export const useToggleLikePost = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { postId: string; userId: string }>({
    mutationFn: ({ postId, userId }) => toggleLikePost(postId, userId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['posts'] }),
  });
};

export const useDeletePost = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: deletePost,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['posts'] }),
  });
};

export const useAddComment = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { postId: string; comment: Omit<Comment, 'id' | 'timestamp'> }>({
    mutationFn: addComment,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['posts'] }),
  });
};