import React from 'react';
import { Post } from '../../types';
import { motion } from 'framer-motion';

interface SeeMoreModalProps {
  photos: Post[];
  onClose: () => void;
  onImageClick: (url: string) => void;
}

const SeeMoreModal: React.FC<SeeMoreModalProps> = ({ photos, onClose, onImageClick }) => {
  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <dialog className="modal" open>
      <div className="modal-box w-[80vw] max-h-[90vh] overflow-y-auto">
        <h3 className="font-bold text-lg mb-4">All Photos</h3>
        <div className="flex flex-col gap-4">
          {photos.map((photo, index) => (
            <motion.img
              key={photo.id}
              src={photo.imageUrl}
              alt="Photo"
              className="w-full h-64 object-cover rounded-lg cursor-pointer"
              onClick={() => onImageClick(photo.imageUrl || '')}
              custom={index}
              initial="hidden"
              animate="visible"
              variants={variants}
            />
          ))}
        </div>
        <div className="modal-action mt-4">
          <button className="btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default SeeMoreModal;
