import React from 'react';

const RightPanel: React.FC = () => {
  return (
    <div className="hidden md:block w-72 h-screen flex-shrink-0 bg-gray-800">
      <div className="sticky top-0 right-0 h-full flex flex-col border-l border-gray-700">
        <div className="flex justify-center items-center py-4"></div>
      </div>
    </div>
  );
};

export default RightPanel;
