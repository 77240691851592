import { useQuery } from '@tanstack/react-query';
import { fetchUserById, fetchFriends } from '../utils/api';

const getFriendStatus = async (currentUserId: string, profileUserId: string) => {
  try {
    const currentUser = await fetchUserById(currentUserId);

    const friends = await fetchFriends(currentUserId);

    const isFriend = friends.some(friend => friend.userId === profileUserId);
    const hasSentRequest = currentUser.sentFriendRequests?.includes(profileUserId) ?? false;


    return { isFriend, hasSentRequest };
  } catch (error) {
    console.error('Error in getFriendStatus:', error);
    return { isFriend: false, hasSentRequest: false };
  }
};

export const useFriendStatus = (currentUserId: string, profileUserId: string) => {
  return useQuery({
    queryKey: ['friendStatus', currentUserId, profileUserId],
    queryFn: () => getFriendStatus(currentUserId, profileUserId),
    enabled: !!currentUserId && !!profileUserId,
  });
};