import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAccount } from '../utils/api';

export const useDeleteAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>({
    mutationFn: deleteAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['profile'] });
      queryClient.invalidateQueries({ queryKey: ['friends'] });
    },
    onError: (error) => {
      console.error('Failed to delete account:', error);
      alert('Failed to delete account. Please try again later.');
    },
  });
};