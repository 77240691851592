import React, { useState } from 'react';
import { useCreatePost } from '../../hooks/usePosts';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { User } from '../../types';
import { useQueryClient } from '@tanstack/react-query';
import { useImageUpload } from '../../hooks/useImageUpload';
import { FaCamera } from 'react-icons/fa';

interface CreatePostProps {
  userData: User | null;
  onClose: () => void;
}

const CreatePost: React.FC<CreatePostProps> = ({ userData }) => {
  const [content, setContent] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const createPostMutation = useCreatePost();
  const queryClient = useQueryClient();

  const openModal = () => {
    const modal = document.getElementById('create_post_modal') as HTMLDialogElement;
    modal.showModal();
  };

  const closeModal = () => {
    const modal = document.getElementById('create_post_modal') as HTMLDialogElement;
    modal.close();
  };

  const handleCreatePost = () => {
    if (!userData) {
      alert('User data is not available');
      return;
    }

    createPostMutation.mutate(
      { content, userId: userData.id, file: file ?? undefined, isPrivate },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['posts'] });
          setContent('');
          setFile(null);
          setIsPrivate(false);
          closeModal();
        },
      }
    );
  };

  const handleImageUploadSuccess = (url: string, uploadedFile: File) => {
    setFile(uploadedFile);
  };

  const { getRootProps, getInputProps, preview } = useImageUpload(handleImageUploadSuccess);

  if (!userData) {
    return null;
  }

  const isPostDisabled = !content.trim() && !file;

  return (
    <>
      <button
        className="btn bg-primary text-gray-900 rounded w-full"
        onClick={openModal}
      >
        Create Post
      </button>

      <dialog id="create_post_modal" className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Create Post</h3>
          <textarea
            className="w-full p-2 border border-gray-300 rounded-lg"
            rows={4}
            placeholder="Write something..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <button type="button" className="text-gray-400 hover:text-gray-600">
              <FaCamera size={24} />
            </button>
          </div>
          {preview && (
            <div className="mt-2">
              <img src={preview} alt="Preview" className="w-full h-auto rounded" />
            </div>
          )}
          <div className="flex items-center mt-4">
            <label className="mr-2">Private Post</label>
            <input
              type="checkbox"
              checked={isPrivate}
              onChange={() => setIsPrivate(!isPrivate)}
              className="toggle"
            />
          </div>
          <div className="modal-action">
            <button className="btn" onClick={closeModal}>
              Close
            </button>
            <button
              className={`btn bg-primary text-black ${isPostDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={handleCreatePost}
              disabled={isPostDisabled}
              title={isPostDisabled ? 'Content or image is required' : ''}
            >
              Post
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
};

export default CreatePost;