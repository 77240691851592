import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchUserByUsername, fetchPosts, updateUserProfile } from '../utils/api';
import { Profile, Post, User } from '../types';

const fetchProfile = async ({ queryKey }: { queryKey: [string, string] }): Promise<Profile> => {
  const [, username] = queryKey;
  const profileData = await fetchUserByUsername(username);
  const postsData: Post[] = await fetchPosts();
  const userPosts = postsData.filter(post => post.userId === profileData.id);
  return { ...profileData, posts: userPosts };
};

export const useProfile = (username: string) => {
  return useQuery({
    queryKey: ['profile', username],
    queryFn: fetchProfile,
    enabled: !!username,
  });
};

export const useUpdateUserProfile = () => {
  const queryClient = useQueryClient();
  return useMutation<User, Error, Partial<User>>({
    mutationFn: updateUserProfile,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    },
  });
};