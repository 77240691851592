import React from 'react';
import { User } from '../../types';

interface PendingFriendRequestsProps {
  sentRequests: User[];
}

const PendingFriendRequests: React.FC<PendingFriendRequestsProps> = ({ sentRequests }) => {
  if (sentRequests.length === 0) {
    return <p>No pending friend requests.</p>;
  }

  return (
    <div>
      {sentRequests.map((request) => (
        <div key={request.id} className="flex items-center p-2 mb-2 bg-gray-800 rounded-lg hover:bg-gray-700">
          <div className="avatar">
            <div className="w-10 rounded-full">
              <img src={request.profileImg} alt={request.userName} />
            </div>
          </div>
          <div className="ml-3 flex-grow">
            <p className="text-white font-bold">{request.userName}</p>
            <p className="text-gray-400">@{request.userName}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PendingFriendRequests;