import React, { useState, useEffect } from 'react';
import { useSendFriendRequest } from '../../hooks/useFriendRequests';
import EditProfileModal from './EditProfileModal';
import { Profile, User } from '../../types';
import { useFriendStatus } from '../../hooks/useFriendStatus';
import { useUpdateUserProfile, useProfile } from '../../hooks/useProfile';
import ProfileImageUploadModal from './ProfileImageUploadModal';
import { FaCamera } from 'react-icons/fa';

interface ProfileComponentProps {
  profile: Profile;
  currentUser: User | null;
}

const ProfileComponent: React.FC<ProfileComponentProps> = ({ profile, currentUser }) => {
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState(false);
  const { data: friendStatus, isLoading: isFriendStatusLoading, refetch: refetchFriendStatus } = useFriendStatus(currentUser?.id || '', profile.id);
  const { mutate: sendFriendRequestMutation, status: sendFriendRequestStatus } = useSendFriendRequest();
  const updateUserProfileMutation = useUpdateUserProfile();
  const { refetch: refetchProfile } = useProfile(profile.userName);

  const handleUpdateUserProfile = (updatedProfile: Partial<User>) => {
    updateUserProfileMutation.mutate(updatedProfile, {
      onSuccess: async () => {
        await refetchProfile();
        setIsEditProfileModalOpen(false);
      },
      onError: (error) => {
        console.error("Error updating profile:", error);
      }
    });
  };

  const handleAddFriend = () => {
    if (currentUser) {
      sendFriendRequestMutation(
        { userId: currentUser.id, friendId: profile.id },
        {
          onSuccess: () => {
            refetchFriendStatus();
          },
        }
      );
    }
  };

  const openEditProfileModal = () => setIsEditProfileModalOpen(true);
  const closeEditProfileModal = () => setIsEditProfileModalOpen(false);

  const openImageUploadModal = () => {
    if (isCurrentUser) {
      setIsImageUploadModalOpen(true);
    }
  };
  
  const closeImageUploadModal = () => setIsImageUploadModalOpen(false);

  const handleImageUploadSuccess = async (url: string) => {
    await handleUpdateUserProfile({ profileImg: url });
    await refetchProfile();
    closeImageUploadModal();
  };

  const isCurrentUser = profile.userName === currentUser?.userName;
  const isFriend = friendStatus?.isFriend;
  const hasSentRequest = friendStatus?.hasSentRequest;

  useEffect(() => {
  }, [profile, currentUser, friendStatus]);

  const buttonState = {
    pending: {
      text: 'Pending',
      disabled: true,
      classes: 'btn btn-warning btn-disabled',
    },
    default: {
      text: 'Add Friend',
      disabled: false,
      classes: 'btn btn-primary',
    },
    friends: {
      text: 'Friends',
      disabled: true,
      classes: 'btn btn-outline btn-accent',
    },
  };

  let buttonConfig = buttonState.default;
  if (sendFriendRequestStatus === 'pending') buttonConfig = buttonState.pending;
  if (isFriend) buttonConfig = buttonState.friends;
  if (hasSentRequest) buttonConfig = buttonState.pending;

  if (isFriendStatusLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card w-full max-w-md bg-gray-800 shadow-xl">
      <figure className="px-10 pt-10 relative">
        <div className="relative w-24 h-24">
          <img
            src={profile.profileImg || '/avatars/boy1.png'}
            alt="Profile"
            className={`rounded-full object-cover w-24 h-24 max-w-xs ${isCurrentUser ? 'cursor-pointer' : ''}`}
          />
          {isCurrentUser && (
            <div
              className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-white opacity-0 hover:opacity-100 transition-opacity duration-300 cursor-pointer"
              onClick={openImageUploadModal}
            >
              <FaCamera size={24} />
            </div>
          )}
        </div>
      </figure>
      <div className="card-body items-center text-center">
        <h2 className="card-title">@{profile.userName}</h2>
        <p>{profile.bio || 'This is a bio'}</p>
        <div className="card-actions">
          {isCurrentUser ? (
            <button className="btn btn-primary" onClick={openEditProfileModal}>
              Edit Profile
            </button>
          ) : (
            <button className={buttonConfig.classes} onClick={handleAddFriend} disabled={buttonConfig.disabled}>
              {buttonConfig.text}
            </button>
          )}
        </div>
      </div>
      {isEditProfileModalOpen && (
        <EditProfileModal profile={profile} onClose={closeEditProfileModal} updateUserProfile={handleUpdateUserProfile} />
      )}
      {isImageUploadModalOpen && (
        <ProfileImageUploadModal
          isOpen={isImageUploadModalOpen}
          onClose={closeImageUploadModal}
          onUploadSuccess={handleImageUploadSuccess}
          userId={currentUser?.id || ''}
        />
      )}
    </div>
  );
};

export default ProfileComponent;
