import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OrangeMellonLogo from '../components/svgs/OrangeMellonLogo';
import { MdOutlineMail, MdPassword, MdDriveFileRenameOutline } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { registerUser } from '../utils/api';
import { UserRegistrationData } from '../types';

const SignUpPage: React.FC = () => {
  const [formData, setFormData] = useState<UserRegistrationData>({
    email: '',
    userName: '',
    firstName: '',
    lastName: '',
    hashedPassword: '',
    profileImg: '/avatars/boy1.png',
    bio: 'This is a bio.',
  });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Environment Variables:', import.meta.env);
    console.log('API Base URL:', import.meta.env.VITE_API_BASE_URL);
  }, []);


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format.';
    }
    if (!formData.userName) errors.userName = 'Username is required.';
    if (!formData.firstName) errors.firstName = 'First name is required.';
    if (!formData.lastName) errors.lastName = 'Last name is required.';
    if (!formData.hashedPassword) errors.hashedPassword = 'Password is required.';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const data = await registerUser(formData);

      console.log('Signup successful', data);
      navigate('/login');
    } catch (error) {
      setIsError(true);
      setErrorMessage((error as Error).message);
      console.error('Signup failed', error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: '' }));
  };

  return (
    <div className="max-w-screen-xl mx-auto flex h-screen px-10">
      <div className="flex-1 hidden lg:flex items-center justify-center">
        <OrangeMellonLogo className="lg:w-2/3 fill-white" />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <form
          className="lg:w-2/3 mx-auto md:mx-20 flex gap-4 flex-col"
          onSubmit={handleSubmit}
        >
          <OrangeMellonLogo className="w-24 lg:hidden fill-white" />
          <h1 className="text-4xl font-extrabold text-white">Join today.</h1>

          <label className="input input-bordered rounded flex items-center gap-2">
            <MdOutlineMail />
            <input
              type="email"
              className="grow"
              placeholder="Email"
              name="email"
              onChange={handleInputChange}
              value={formData.email}
            />
          </label>
          {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}

          <div className="flex gap-4 flex-wrap">
            <label className="input input-bordered rounded flex items-center gap-2 flex-1">
              <FaUser />
              <input
                type="text"
                className="grow"
                placeholder="Username"
                name="userName"
                onChange={handleInputChange}
                value={formData.userName}
              />
            </label>
            {validationErrors.userName && <p className="text-red-500">{validationErrors.userName}</p>}

            <label className="input input-bordered rounded flex items-center gap-2 flex-1">
              <MdDriveFileRenameOutline />
              <input
                type="text"
                className="grow"
                placeholder="First Name"
                name="firstName"
                onChange={handleInputChange}
                value={formData.firstName}
              />
            </label>
            {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}

            <label className="input input-bordered rounded flex items-center gap-2 flex-1">
              <MdDriveFileRenameOutline />
              <input
                type="text"
                className="grow"
                placeholder="Last Name"
                name="lastName"
                onChange={handleInputChange}
                value={formData.lastName}
              />
            </label>
            {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
          </div>

          <label className="input input-bordered rounded flex items-center gap-2">
            <MdPassword />
            <input
              type="password"
              className="grow"
              placeholder="Password"
              name="hashedPassword"
              onChange={handleInputChange}
              value={formData.hashedPassword}
            />
          </label>
          {validationErrors.hashedPassword && <p className="text-red-500">{validationErrors.hashedPassword}</p>}

          <button className="btn rounded-full btn-primary text-grey">
            Sign up
          </button>
          {isError && <p className="text-red-500">{errorMessage}</p>}
        </form>
        <div className="flex flex-col lg:w-2/3 gap-2 mt-4">
          <p className="text-white text-lg">Already have an account?</p>
          <Link to="/login">
            <button className="btn rounded-full btn-primary text-white btn-outline w-full">
              Sign in
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
