import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Post, Comment } from '../../types';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Link } from 'react-router-dom';

interface CommentsModalProps {
  post: Post;
  onClose: () => void;
  onAddComment: (postId: string, comment: Comment) => void;
}

const CommentsModal: React.FC<CommentsModalProps> = ({ post, onClose, onAddComment }) => {
  const [commentText, setCommentText] = useState('');
  const { data: currentUser } = useCurrentUser();

  const handleAddComment = () => {
    if (commentText.trim() && currentUser) {
      const newComment: Omit<Comment, 'id' | 'timestamp'> = {
        userId: currentUser.id,
        username: currentUser.userName,
        profileImg: currentUser.profileImg || '',
        content: commentText.trim(),
      };
      onAddComment(post.id, newComment as Comment);
      setCommentText('');
    }
  };

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Comments</h3>
        <div className="original-post mb-4 p-4 bg-gray-800 rounded-lg">
          <div className="flex items-center mb-4">
            <img
              src={post.profileImg || '/avatars/boy1.png'}
              alt="Profile"
              className="w-10 h-10 rounded-full mr-3"
            />
            <div>
              <div className="text-white font-bold">{post.username}</div>
              <div className="text-gray-400 text-sm">{new Date(post.timestamp).toLocaleString()}</div>
            </div>
          </div>
          <p className="text-white">{post.content}</p>
          {post.imageUrl && <img src={post.imageUrl} alt="Post" className="w-full mt-2 rounded" />}
        </div>
        <div className="comments-list mb-4">
          {post.comments.map((comment: Comment, index: number) => (
            <motion.div
              key={index}
              className="comment bg-gray-700 p-2 rounded mb-2"
              custom={index}
              initial="hidden"
              animate="visible"
              variants={variants}
            >
              <div className="flex items-center mb-2">
                <img
                  src={comment.profileImg || '/avatars/boy1.png'}
                  alt="Commenter Profile"
                  className="w-8 h-8 rounded-full mr-3"
                />
                <div>
                  <Link to={`/profile/${comment.username}`} className="text-white font-bold">
                    {comment.username}
                  </Link>
                  <p className="text-slate-50 text-xs">{new Date(comment.timestamp).toLocaleString()}</p>
                </div>
              </div>
              <p className="text-white">{comment.content}</p>
            </motion.div>
          ))}
        </div>
        <div className="modal-action flex flex-col">
          <input
            type="text"
            placeholder="Add a comment..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-white mb-2"
          />
          <div className="flex justify-end space-x-2">
            <button className="btn btn-primary" onClick={handleAddComment}>
              {'Add Comment'}
            </button>
            <button className="btn" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default CommentsModal;