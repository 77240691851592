import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import CustomConfirmationModal from '../common/CustomConfirmationModal';

interface FriendItemProps {
  friend: {
    userId: string;
    username: string;
    profileImg?: string;
  };
  onRemoveFriend: (friendUserId: string) => void;
}

const FriendItem: React.FC<FriendItemProps> = ({ friend, onRemoveFriend }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemoveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const handleConfirmRemove = () => {
    onRemoveFriend(friend.userId);
    setIsModalOpen(false);
  };

  const handleCancelRemove = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-between items-center p-4 border-b border-gray-700">
      <Link to={`/profile/${friend.username}`} className="flex items-center flex-grow">
        <div className="avatar">
          <div className="w-10 rounded-full">
            <img src={friend.profileImg || '/avatars/boy1.png'} alt={friend.username} />
          </div>
        </div>
        <div className="ml-3">
          <p className="text-white font-bold">{friend.username}</p>
          <p className="text-gray-400">@{friend.username}</p>
        </div>
      </Link>
      <button
        className="btn btn-secondary"
        onClick={handleRemoveClick}
        title={`Remove ${friend.username}`}
        aria-label={`Remove ${friend.username}`}
      >
        <IoClose />
      </button>
      <CustomConfirmationModal
        isOpen={isModalOpen}
        message={`Are you sure you want to remove ${friend.username} from your friends?`}
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
      />
    </div>
  );
};

export default FriendItem;