import React, { useState } from 'react';
import { useProfileImageUpload } from '../../hooks/useImageUpload';

interface ProfileImageUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUploadSuccess: (url: string) => void;
  userId: string;
}

const ProfileImageUploadModal: React.FC<ProfileImageUploadModalProps> = ({ isOpen, onClose, onUploadSuccess, userId }) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

  const { getRootProps, getInputProps, preview } = useProfileImageUpload(userId, (url: string, file: File) => {
    setImageFile(file);
    setUploadedUrl(url);
  });

  const handleConfirmUpload = () => {
    if (imageFile && uploadedUrl) {
      onUploadSuccess(uploadedUrl);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-75 absolute inset-0" onClick={onClose}></div>
      <div className="bg-gray-800 p-6 rounded-lg z-10 max-w-2xl max-h-full overflow-auto text-white">
        <h3 className="font-bold text-lg">Upload New Profile Image</h3>
        <div {...getRootProps()} className="dropzone mt-4 border-dashed border-4 border-gray-300 p-4 text-center">
          <input {...getInputProps()} />
          {preview ? (
            <img src={preview} alt="Preview" className="w-full h-auto rounded-lg mt-4" />
          ) : (
            <p>Drag & drop an image here, or click to select a file</p>
          )}
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button className="btn bg-gray-600 text-white" onClick={onClose}>
            Close
          </button>
          <button className="btn bg-primary text-black" onClick={handleConfirmUpload} disabled={!imageFile}>
            Confirm Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileImageUploadModal;
