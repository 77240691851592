import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OrangeMellonLogo from '../components/svgs/OrangeMellonLogo';
import { MdPassword } from 'react-icons/md';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { loginUser } from '../utils/api';
import { LoginRequest } from '../types';
import { FaUser } from 'react-icons/fa';

const LoginPage: React.FC = () => {
  const [formData, setFormData] = useState<LoginRequest>({ username: '', password: '' });
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const { refetch } = useCurrentUser();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await loginUser(formData);
      await refetch();
      navigate('/');
    } catch (error) {
      setIsError(true);
      console.error('Login failed', error);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-w-screen-xl mx-auto flex h-screen">
      <div className="flex-1 hidden lg:flex items-center justify-center">
        <OrangeMellonLogo className="lg:w-2/3 fill-white" />
      </div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <form className="flex gap-4 flex-col" onSubmit={handleSubmit}>
          <OrangeMellonLogo className="w-24 lg:hidden fill-white" />
          <h1 className="text-4xl font-extrabold text-white">Login.</h1>
          <label className="input input-bordered rounded flex items-center gap-2">
            <FaUser />
            <input
              type="text"
              className="grow"
              placeholder="username"
              name="username"
              onChange={handleInputChange}
              value={formData.username}
            />
          </label>
          <label className="input input-bordered rounded flex items-center gap-2">
            <MdPassword />
            <input
              type="password"
              className="grow"
              placeholder="Password"
              name="password"
              onChange={handleInputChange}
              value={formData.password}
            />
          </label>
          <button className="btn rounded-full btn-primary text-grey">
            Login
          </button>
          {isError && <p className="text-red-500">Something went wrong</p>}
        </form>
        <div className="flex flex-col gap-2 mt-4">
          <p className="text-white text-lg">Don't have an account?</p>
          <Link to="/signup">
            <button className="btn rounded-full btn-primary text-white btn-outline w-full">
              Sign up
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;