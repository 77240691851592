import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchFriends, deleteFriend } from '../utils/api';
import { Friend } from '../types';

export const useFetchFriends = (userId: string) => {
  return useQuery<Friend[], Error>({
    queryKey: ['friends', userId],
    queryFn: () => fetchFriends(userId),
    enabled: !!userId,
  });
};

export const useDeleteFriend = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { userId: string; friendUserId: string }>({
    mutationFn: deleteFriend,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['friends', variables.userId] });
    },
  });
};
