import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useProfile } from '../hooks/useProfile';
import { useCurrentUser } from '../hooks/useCurrentUser';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import Rightpanel from '../components/common/Rightpanel';
import ProfileComponent from '../components/common/ProfileComponent';
import ProfilePhotoShowcase from '../components/common/ProfilePhotoShowcase';
import ProfileFeed from '../components/common/ProfileFeed';

const ProfilePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();
  const { data: profile, isLoading: isProfileLoading, error } = useProfile(username || '');

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <>
      <Header />
      <div className="flex w-full h-screen overflow-hidden">
        <Sidebar />
        <motion.div
          className="flex-1 bg-gray-900 p-4 pt-24 md:pt-16 overflow-y-auto"
          initial="hidden"
          animate="visible"
          variants={variants}
          custom={0}
        >
          {isCurrentUserLoading || isProfileLoading ? (
            <div>Loading...</div>
          ) : error || !profile ? (
            <div className="flex flex-col items-center justify-center w-full h-full text-white text-center">
              <h1 className="text-2xl">No profile found</h1>
              <p>The profile you are looking for does not exist.</p>
            </div>
          ) : (
            <div className="flex flex-col gap-4 mt-4">
              <motion.div
                className="flex flex-col md:flex-row gap-4"
                initial="hidden"
                animate="visible"
                variants={variants}
                custom={1}
              >
                <ProfileComponent profile={profile} currentUser={currentUser || null} />
                <ProfilePhotoShowcase
                  photos={(profile.posts || []).filter((post) => post.imageUrl).slice(0, 6)}
                  currentUser={currentUser || null}
                  profile={profile}
                />
              </motion.div>
              <ProfileFeed profileUserId={profile.id} />
            </div>
          )}
        </motion.div>
        <Rightpanel />
      </div>
    </>
  );
};

export default ProfilePage;
