import React, { useState } from 'react';
import { IoTrashBinOutline } from 'react-icons/io5';
import FullImageModal from './FullImageModal';
import ImageUploadModal from './ImageUploadModal';
import CustomConfirmationModal from './CustomConfirmationModal';
import { Post, User, Profile } from '../../types';
import { useCreatePost, useDeletePost } from '../../hooks/usePosts';
import SeeMoreModal from './SeeMoreModal';

interface ProfilePhotoShowcaseProps {
  photos: Post[];
  currentUser: User | null;
  profile: Profile;
}

const ProfilePhotoShowcase: React.FC<ProfilePhotoShowcaseProps> = ({
  photos,
  currentUser,
  profile,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = useState<boolean>(false);
  const [uploadedPhotos, setUploadedPhotos] = useState<Post[]>(photos);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [photoToDelete, setPhotoToDelete] = useState<string | null>(null);
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState<boolean>(false);

  const createPostMutation = useCreatePost();
  const deletePostMutation = useDeletePost();

  const handleThumbnailClick = (url: string) => {
    setSelectedPhoto(url);
    setIsSeeMoreModalOpen(false);
  };

  const handleUploadClick = () => {
    setIsImageUploadModalOpen(true);
  };

  const closeImageUploadModal = () => {
    setIsImageUploadModalOpen(false);
  };

  const handleUploadSuccess = (url: string, file: File, isPrivate: boolean) => {
    if (!currentUser) return;

    const newPhoto: Post = {
      id: Date.now().toString(),
      imageUrl: url,
      content: '',
      userId: currentUser.id,
      username: currentUser.userName,
      timestamp: new Date().toISOString(),
      likes: [],
      comments: [],
      isPrivate: isPrivate,
    };

    createPostMutation.mutate(
      { content: '', userId: currentUser.id, file, isPrivate: newPhoto.isPrivate },
      {
        onSuccess: (data) => {
          setUploadedPhotos((prev) => [...prev, { ...newPhoto, id: data.id }]);
          closeImageUploadModal();
        },
        onError: (error) => {
          console.error('Failed to upload image:', error);
          alert('Failed to upload image.');
        },
      }
    );
  };

  const handleDeleteClick = (photoId: string) => {
    setPhotoToDelete(photoId);
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (photoToDelete) {
      deletePostMutation.mutate(photoToDelete, {
        onSuccess: () => {
          setUploadedPhotos((prev) => prev.filter((photo) => photo.id !== photoToDelete));
          setPhotoToDelete(null);
          setIsConfirmationModalOpen(false);
        },
        onError: (error) => {
          console.error('Failed to delete image:', error);
          alert('Failed to delete image.');
        },
      });
    }
  };

  const handleCancelDelete = () => {
    setPhotoToDelete(null);
    setIsConfirmationModalOpen(false);
  };

  const handleSeeMoreClick = () => {
    setIsSeeMoreModalOpen(true);
  };

  const handleCloseSeeMoreModal = () => {
    setIsSeeMoreModalOpen(false);
  };

  const isProfileOwner = profile.userName === currentUser?.userName;
  const totalContainers = 6;
  const photoElements = uploadedPhotos.map((photo) => (
    <div key={photo.id} className="relative h-36 w-full group">
      <img
        src={photo.imageUrl || '/path/to/placeholder.jpg'}
        alt="Thumbnail"
        className="h-full w-full object-cover rounded-lg shadow-md cursor-pointer transform transition-transform duration-300 hover:scale-105"
        onClick={() => handleThumbnailClick(photo.imageUrl || '')}
      />
      {isProfileOwner && (
        <button
          title="Delete photo"
          className="absolute top-2 right-2 text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(photo.id);
          }}
        >
          <IoTrashBinOutline className="w-6 h-6" />
        </button>
      )}
    </div>
  ));

  const placeholderElements = Array.from({ length: totalContainers - uploadedPhotos.length }).map(
    (_, index) => (
      <div
        key={`placeholder-${index}`}
        className="bg-gray-700 h-36 w-full rounded-lg flex items-center justify-center transform transition-transform duration-300 hover:scale-105 cursor-pointer"
        onClick={isProfileOwner ? handleUploadClick : undefined}
      >
        {isProfileOwner ? <span className="text-white">Upload Photo</span> : `Recent Photo ${index + 1}`}
      </div>
    )
  );

  return (
    <div className="card w-full h-full bg-gray-800 shadow-xl p-4 flex-grow xxl:mx-24">
      <h3 className="text-white font-bold mb-2">Recent Photos</h3>
      <div className="grid grid-cols-3 gap-4">
        {[...photoElements, ...placeholderElements]}
      </div>
      <div className="mt-4 flex justify-center">
        <button className="btn bg-primary text-black" onClick={handleSeeMoreClick}>
          See More
        </button>
      </div>
      {selectedPhoto && <FullImageModal url={selectedPhoto} onClose={() => setSelectedPhoto(null)} />}
      {isImageUploadModalOpen && currentUser && isProfileOwner && (
        <ImageUploadModal
          isOpen={isImageUploadModalOpen}
          onClose={closeImageUploadModal}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
      {isConfirmationModalOpen && (
        <CustomConfirmationModal
          isOpen={isConfirmationModalOpen}
          message="Are you sure you want to delete this photo?"
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {isSeeMoreModalOpen && (
        <SeeMoreModal
          photos={uploadedPhotos}
          onClose={handleCloseSeeMoreModal}
          onImageClick={handleThumbnailClick}
        />
      )}
    </div>
  );
};

export default ProfilePhotoShowcase;
