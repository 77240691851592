import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchNotifications, deleteNotification, deleteAllNotifications } from '../utils/api';
import { Notification } from '../types';

export const useFetchNotifications = (userId: string, options?: { onError?: () => void; onSuccess?: (data: Notification[]) => void }) => {
  return useQuery<Notification[], Error>({
    queryKey: ['notifications', userId],
    queryFn: () => fetchNotifications(userId),
    enabled: !!userId,
    ...options,
  });
};

export const useDeleteNotification = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: (notificationId: string) => deleteNotification(notificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', userId] });
    },
  });
};


export const useDeleteAllNotifications = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: (userId: string) => deleteAllNotifications(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', userId] });
    },
  });
};