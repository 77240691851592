import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import NotificationPage from './pages/NotificationPage';
import ProfilePage from './pages/ProfilePage';
import FriendsPage from './pages/FriendsPage';
import SettingsPage from './pages/SettingsPage';
import PrivateRoute from './components/PrivateRoute';
import { useCurrentUser } from './hooks/useCurrentUser';

const AppRoutes: React.FC = () => {
  const { isLoading } = useCurrentUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
      <Route
        path="/notifications"
        element={<PrivateRoute element={<NotificationPage />} />}
      />
      <Route
        path="/profile/:username"
        element={<PrivateRoute element={<ProfilePage />} />}
      />
      <Route
        path="/friends"
        element={<PrivateRoute element={<FriendsPage />} />}
      />
      <Route
        path="/settings"
        element={<PrivateRoute element={<SettingsPage />} />}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <div className="w-auto">
      <AppRoutes />
    </div>
  );
};

export default App;
