import { API_BASE_URL } from './config';
import { User, LoginRequest, LoginResponse, Post, Comment, FriendRequestParams, Notification, Friend, UserRegistrationData } from '../types';
import axios from 'axios';

const getAuthHeaders = (): HeadersInit => {
  const token = sessionStorage.getItem('token');

  const headers: HeadersInit = {
      'Content-Type': 'application/json',
  };
  if (token) {
      headers['Authorization'] = `Bearer ${token.trim()}`;
  }
  return headers;
};

// User API calls
// export const fetchUserById = async (userId: string): Promise<User> => {
//   const response = await fetch(`${API_BASE_URL}/User/${userId}`, {
//     headers: getAuthHeaders(),
//   });
//   if (!response.ok) {
//     throw new Error(`Failed to fetch user by ID ${userId}`);
//   }
//   return response.json();
// };

export const fetchUserById = async (userId: string): Promise<User> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch user by ID ${userId}`);
  }
  return response.json();
};


export const fetchUserByUsername = async (username: string): Promise<User> => {
  const response = await fetch(`${API_BASE_URL}/User?username=${username}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch user by username ${username}`);
  }
  return response.json();
};

export const fetchUsernameById = async (userId: string): Promise<string> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/username`);
  if (!response.ok) {
    throw new Error('Failed to fetch username');
  }
  const data = await response.json();
  return data.Username;
};

export const registerUser = async (userData: UserRegistrationData): Promise<any> => {
  const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/User/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    if (response.status === 409) {
      throw new Error('Username is already taken.');
    } else {
      throw new Error('Network response was not ok');
    }
  }

  return await response.json();
};

export const deleteAccount = async (userId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to delete account');
  }
};


export const updateUserProfile = async (updatedUser: Partial<User>): Promise<User> => {
  if (!updatedUser.id) {
    throw new Error('User ID is required to update the profile');
  }

  const response = await fetch(`${API_BASE_URL}/User/${updatedUser.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(updatedUser),
  });

  if (!response.ok) {
    throw new Error(`Failed to update user profile ${updatedUser.id}`);
  }

  if (response.status !== 204) {
    return response.json();
  }

  return updatedUser as User;
};

export const loginUser = async (formData: LoginRequest): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/User/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData),
  });

  if (!response.ok) {
    throw new Error('Failed to log in');
  }

  const data = await response.json();

  if (data.token.split('.').length === 3) {
    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('userId', data.userId);
  } else {
    throw new Error('Received token is not in correct format');
  }
};

// export const loginUser = async (formData: LoginRequest): Promise<LoginResponse> => {
//   const response = await fetch(`${API_BASE_URL}/User/login`, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify(formData),
//   });
//   if (!response.ok) {
//     throw new Error('Failed to log in');
//   }
//   return response.json();
// };

export const fetchCurrentUser = async (): Promise<User> => {
  const userId = sessionStorage.getItem('userId');
  if (!userId) {
    throw new Error('User ID not found');
  }
  return fetchUserById(userId);
};

// Post API calls
export const fetchPosts = async (): Promise<Post[]> => {
  const response = await fetch(`${API_BASE_URL}/Post`);
  if (!response.ok) {
    throw new Error('Failed to fetch posts');
  }
  return response.json();
};

export const fetchUserPosts = async (userId: string): Promise<Post[]> => {
  const response = await fetch(`${API_BASE_URL}/Post/user/${userId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch user posts');
  }
  return response.json();
};

export const createPost = async (content: string, userId: string, file?: File, isPrivate?: boolean): Promise<Post> => {
  const formData = new FormData();
  formData.append('content', content);
  formData.append('userId', userId);
  formData.append('isPrivate', String(isPrivate ?? false));
  if (file) {
    formData.append('file', file);
  }

  const token = sessionStorage.getItem('token');

  const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/Post/create`, formData, {
    headers: {
      'Authorization': token ? `Bearer ${token.trim()}` : '',
      'Content-Type': 'multipart/form-data',
    },
  });

  if (response.status !== 201) {
    throw new Error('Failed to create post');
  }

  return response.data;
};

export const updatePost = async ({ postId, userId, content, removeImage, isPrivate }: { postId: string; userId: string; content: string; removeImage: boolean, isPrivate: boolean }): Promise<Post> => {
  if (!postId) {
    throw new Error('Post ID is required to update the post');
  }
  const updateData: Partial<Post> = {
    content,
    imageUrl: removeImage ? '' : undefined,
    isPrivate
  };

  const queryParams = new URLSearchParams({ userId }).toString();

  const response = await fetch(`${API_BASE_URL}/Post/${postId}?${queryParams}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(updateData),
  });

  if (!response.ok) {
    throw new Error(`Failed to update post ${postId}`);
  }

  if (response.status !== 204) {
    return response.json();
  }

  return { ...updateData, id: postId, imageUrl: updateData.imageUrl || undefined } as Post;
};



export const toggleLikePost = async (postId: string, userId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/Post/${postId}/like`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(userId),
  });
  if (!response.ok) {
    throw new Error(`Failed to toggle like on post ${postId}`);
  }
};

export const addComment = async ({ postId, comment }: { postId: string; comment: Omit<Comment, 'id' | 'timestamp'> }): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/Post/${postId}/comment`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(comment),
  });
  if (!response.ok) {
    throw new Error(`Failed to add comment to post ${postId}`);
  }
};

export const deletePost = async (postId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/Post/${postId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to delete post ${postId}`);
  }
};

// Friend Request API calls
export const sendFriendRequest = async ({ userId, friendId }: FriendRequestParams): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/send-friend-request/${friendId}`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to send friend request from ${userId} to ${friendId}`);
  }
};

export const acceptFriendRequest = async ({ userId, friendId }: FriendRequestParams): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/accept-friend-request/${friendId}`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to accept friend request from ${friendId} to ${userId}`);
  }
};

export const removeFriendRequest = async ({ userId, friendId }: FriendRequestParams): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/remove-friend-request/${friendId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to remove friend request from ${friendId} to ${userId}`);
  }
};

export const fetchFriendRequests = async (userId: string): Promise<Notification[]> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/friend-requests`);
  if (!response.ok) {
    throw new Error(`Failed to fetch friend requests for user ${userId}`);
  }
  return response.json();
};

export const fetchSentFriendRequests = async (userId: string): Promise<User[]> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/sent-friend-requests`);
  if (!response.ok) {
    throw new Error(`Failed to fetch sent friend requests for user ${userId}`);
  }
  return response.json();
};

export const fetchFriends = async (userId: string): Promise<Friend[]> => {
  const response = await fetch(`${API_BASE_URL}/User/${userId}/friends`);
  if (!response.ok) {
    throw new Error(`Failed to fetch friends for user ${userId}`);
  }
  return response.json();
};

export const fetchFriendsByIds = async (friendIds: string[]): Promise<Friend[]> => {
  const friends = await Promise.all(friendIds.map(async (id) => {
    const response = await fetch(`${API_BASE_URL}/Friend/${id}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch friend by ID ${id}`);
    }
    return response.json();
  }));
  return friends;
};

export const deleteFriend = async ({ userId, friendUserId }: { userId: string; friendUserId: string }): Promise<void> => {
  console.log(`Deleting friend with userId: ${userId} and friendUserId: ${friendUserId}`);
  const response = await fetch(`${API_BASE_URL}/User/${userId}/remove-friend/${friendUserId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to remove friend ${friendUserId}`);
  }
};

// Notification API calls
export const fetchNotifications = async (userId: string): Promise<Notification[]> => {
  const response = await fetch(`${API_BASE_URL}/Notification/users/${userId}/notifications`);
  if (!response.ok) {
    throw new Error(`Failed to fetch notifications for user ${userId}`);
  }
  return response.json();
};

export const deleteNotification = async (notificationId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/Notification/notification/${notificationId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to delete notification ${notificationId}`);
  }
};

export const deleteAllNotifications = async (userId: string): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/Notification/users/${userId}/notifications`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  if (!response.ok) {
    throw new Error(`Failed to delete all notifications for user ${userId}`);
  }
};

export const searchUsers = async (query: string): Promise<User[]> => {
  const response = await fetch(`${API_BASE_URL}/User/search?query=${query}`);
  if (!response.ok) {
    throw new Error('Failed to search users');
  }
  return response.json();
};

export const uploadProfileImage = async (userId: string, file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/User/${userId}/upload-profile-image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to upload profile image');
  }

  const data = await response.json();
  return data.imageUrl;
};


export const uploadImage = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/Post/upload-image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to upload image');
  }

  const data = await response.json();
  return data.imageUrl;
};



export const fetchPhotos = async (userId: string) => {
  const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/User/${userId}/photos`, {
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch photos');
  }

  const data = await response.json();
  return data.photos;
};

export const fetchAllUsersReport = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/User/reports/all-users`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch all users report:', error);
    throw error;
  }
};

export const fetchUserPostsReport = async (userId: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/Post/reports/user-posts/${userId}`, {
      headers: getAuthHeaders(),
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Failed to fetch posts report for user ${userId}:`, error);
    throw error;
  }
};