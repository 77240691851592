import React from 'react';

interface FullImageModalProps {
  url: string;
  onClose: () => void;
}

const FullImageModal: React.FC<FullImageModalProps> = ({ url, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-75 absolute inset-0" onClick={onClose}></div>
      <div className="relative bg-gray-900 p-4 rounded-lg z-60 max-w-2xl max-h-full overflow-auto">
        <img src={url} alt="Full size" className="w-full h-auto" />
        <button className="btn btn-primary mt-2" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default FullImageModal;