import React, { useState, useEffect } from 'react';
import { useFetchNotifications, useDeleteAllNotifications, useDeleteNotification } from '../hooks/useNotifications';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useAcceptFriendRequest, useRemoveFriendRequest } from '../hooks/useFriendRequests';
import Sidebar from '../components/common/Sidebar';
import Header from '../components/common/Header';
import Rightpanel from '../components/common/Rightpanel';
import FriendRequestList from '../components/friends/FriendRequestList';
import { FriendRequest, Notification } from '../types';
import { IoSettingsOutline, IoTrashBinOutline } from 'react-icons/io5';
import { motion } from 'framer-motion';
import CustomConfirmationModal from '../components/common/CustomConfirmationModal';

const NotificationPage: React.FC = () => {
  const { data: currentUser } = useCurrentUser();
  const userId = currentUser?.id;
  const { data: notifications } = useFetchNotifications(userId || '');
  const deleteAllMutation = useDeleteAllNotifications(userId || '');
  const deleteNotificationMutation = useDeleteNotification(userId || '');
  const acceptFriendRequestMutation = useAcceptFriendRequest();
  const removeFriendRequestMutation = useRemoveFriendRequest();
  const [showDropdown, setShowDropdown] = useState(false);
  const [deletingNotificationId, setDeletingNotificationId] = useState<string | null>(null);

  const [localFriendRequests, setLocalFriendRequests] = useState<FriendRequest[]>([]);
  const [localLikes, setLocalLikes] = useState<Notification[]>([]);
  const [localComments, setLocalComments] = useState<Notification[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (notifications) {
      const friendRequests = notifications
        .filter((notification: Notification) => notification.type === 'friend_request')
        .map((notification: Notification) => ({
          id: notification.id,
          userId: notification.fromUserId,
          username: notification.fromUsername,
          profileImg: notification.fromProfileImg,
          timestamp: notification.timestamp,
        }));
      setLocalFriendRequests(friendRequests);

      const likes = notifications
        .filter((notification: Notification) => notification.type === 'like')
        .map((notification: Notification) => ({ ...notification }));
      setLocalLikes(likes);

      const comments = notifications
        .filter((notification: Notification) => notification.type === 'comment')
        .map((notification: Notification) => ({ ...notification }));
      setLocalComments(comments);
    }
  }, [notifications]);

  const handleDeleteAll = () => {
    if (!userId) return;

    if (localFriendRequests.length > 0) {
      setShowConfirmationModal(true);
    } else {
      executeDeleteAll(userId);
    }
  };

  const executeDeleteAll = (userId: string) => {
    deleteAllMutation.mutate(userId, {
      onSuccess: () => {
        setLocalFriendRequests([]);
        setLocalLikes([]);
        setLocalComments([]);
        setShowDropdown(false);
      },
    });
  };

  const handleDeleteNotification = (notificationId: string) => {
    setDeletingNotificationId(notificationId);
    deleteNotificationMutation.mutate(notificationId, {
      onSuccess: () => {
        setLocalLikes((prevLikes) => prevLikes.filter((like) => like.id !== notificationId));
        setLocalComments((prevComments) => prevComments.filter((comment) => comment.id !== notificationId));
        setDeletingNotificationId(null);
      },
    });
  };

  const handleAcceptFriendRequest = (fromUserId: string) => {
    acceptFriendRequestMutation.mutate(
      { userId: currentUser?.id || '', friendId: fromUserId },
      {
        onSuccess: () => {
          setLocalFriendRequests((prevRequests) =>
            prevRequests.filter((request) => request.userId !== fromUserId)
          );
        },
      }
    );
  };

  const handleRemoveFriendRequest = (fromUserId: string) => {
    removeFriendRequestMutation.mutate(
      { userId: currentUser?.id || '', friendId: fromUserId },
      {
        onSuccess: () => {
          setLocalFriendRequests((prevRequests) =>
            prevRequests.filter((request) => request.userId !== fromUserId)
          );
        },
      }
    );
  };

  return (
    <>
      <Header />
      <div className="flex w-full h-screen overflow-hidden">
        <Sidebar />
        <div className="flex-1 p-4 mt-16 overflow-y-auto bg-gray-900 relative">
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <p className="font-bold text-white">Notifications</p>
            <div className="relative">
              <div
                tabIndex={0}
                role="button"
                className="m-1"
                title="Settings"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <IoSettingsOutline className="w-4" />
              </div>
              {showDropdown && (
                <ul
                  tabIndex={0}
                  className="dropdown-content absolute z-50 menu p-2 shadow bg-base-100 rounded-box w-52 right-0"
                >
                  <li>
                    <button onClick={handleDeleteAll}>
                      Delete all notifications
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-lg font-bold text-white mb-4">Friend Requests</h2>
            <FriendRequestList
              friendRequests={localFriendRequests}
              acceptFriendRequest={handleAcceptFriendRequest}
              removeFriendRequest={handleRemoveFriendRequest}
            />
          </div>

          <div className="mb-8">
            <h2 className="text-lg font-bold text-white mb-4">Likes</h2>
            <div className="space-y-4">
              {localLikes.length === 0 ? (
                <div className="text-white">No likes found.</div>
              ) : (
                localLikes.map((like) => (
                  <motion.div
                    key={like.id}
                    className="bg-gray-800 p-4 rounded-lg shadow-md relative"
                    initial={{ border: '2px solid transparent' }}
                    animate={
                      deletingNotificationId === like.id
                        ? { border: '2px solid red', opacity: 0, height: 0, margin: 0, padding: 0 }
                        : { border: '2px solid transparent' }
                    }
                    transition={{ duration: 1 }}
                  >
                    <button
                      className="absolute top-2 right-2 text-gray-400 hover:text-red-500"
                      onClick={() => handleDeleteNotification(like.id)}
                      title="Delete Notification"
                      type="button"
                    >
                      <IoTrashBinOutline className="w-5 h-5" />
                    </button>
                    <div className="flex items-center mb-4">
                      <img
                        src={like.fromProfileImg || '/avatars/boy1.png'}
                        alt="Profile"
                        className="w-12 h-12 rounded-full mr-3"
                      />
                      <div>
                        <div className="text-white font-bold">@{like.fromUsername}</div>
                        <div className="text-gray-400 text-sm">
                          {new Date(like.timestamp).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="text-white text-left mb-4">
                      <p>liked your post</p>
                    </div>
                  </motion.div>
                ))
              )}
            </div>
          </div>

          <div>
            <h2 className="text-lg font-bold text-white mb-4">Comments</h2>
            <div className="space-y-4">
              {localComments.length === 0 ? (
                <div className="text-white">No comments found.</div>
              ) : (
                localComments.map((comment) => (
                  <motion.div
                    key={comment.id}
                    className="bg-gray-800 p-4 rounded-lg shadow-md relative"
                    initial={{ border: '2px solid transparent' }}
                    animate={
                      deletingNotificationId === comment.id
                        ? { border: '2px solid red', opacity: 0, height: 0, margin: 0, padding: 0 }
                        : { border: '2px solid transparent' }
                    }
                    transition={{ duration: 1 }}
                  >
                    <button
                      className="absolute top-2 right-2 text-gray-400 hover:text-red-500"
                      onClick={() => handleDeleteNotification(comment.id)}
                      title="Delete Notification"
                      type="button"
                    >
                      <IoTrashBinOutline className="w-5 h-5" />
                    </button>
                    <div className="flex items-center mb-4">
                      <img
                        src={comment.fromProfileImg || '/avatars/boy1.png'}
                        alt="Profile"
                        className="w-12 h-12 rounded-full mr-3"
                      />
                      <div>
                        <div className="text-white font-bold">@{comment.fromUsername}</div>
                        <div className="text-gray-400 text-sm">
                          {new Date(comment.timestamp).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="text-white text-left mb-4">
                      <p>commented on your post</p>
                    </div>
                  </motion.div>
                ))
              )}
            </div>
          </div>
        </div>
        <Rightpanel />
      </div>
      <CustomConfirmationModal
        isOpen={showConfirmationModal}
        message="You have pending friend requests. Deleting all notifications will also remove them. Are you sure you want to continue?"
        onConfirm={() => {
          if (userId) executeDeleteAll(userId);
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default NotificationPage;

